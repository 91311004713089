@font-face {
    font-family: 'Roboto';
    src: url('../static/fonts/subset-Roboto-Regular.woff2') format('woff2'),
        url('../static/fonts/subset-Roboto-Regular.woff') format('woff'),
        url('../static/fonts/subset-Roboto-Regular.ttf') format('truetype'),
        url('../static/fonts/subset-Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../static/fonts/Roboto-Medium.woff2') format('woff2'),
        url('../static/fonts/Roboto-Medium.woff') format('woff'),
        url('../static/fonts/Roboto-Medium.ttf') format('truetype'),
        url('../static/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../static/fonts/Roboto-Bold.woff2') format('woff2'),
        url('../static/fonts/Roboto-Bold.woff') format('woff'),
        url('../static/fonts/Roboto-Bold.ttf') format('truetype'),
        url('../static/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../static/fonts/subset-Rubik-Medium.woff2') format('woff2'),
        url('../static/fonts/subset-Rubik-Medium.woff') format('woff'),
        url('../static/fonts/subset-Rubik-Medium.ttf') format('truetype'),
        url('../static/fonts/subset-Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../static/fonts/subset-Rubik-Regular.woff2') format('woff2'),
        url('../static/fonts/subset-Rubik-Regular.woff') format('woff'),
        url('../static/fonts/subset-Rubik-Regular.ttf') format('truetype'),
        url('../static/fonts/subset-Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../static/fonts/subset-Rubik-Light.woff2') format('woff2'),
        url('../static/fonts/subset-Rubik-Light.woff') format('woff'),
        url('../static/fonts/subset-Rubik-Light.ttf') format('truetype'),
        url('../static/fonts/subset-Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
