html, body, #root {
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.header {
  height: 74px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 74px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid #EAEAEA;

  padding-left: 24px;
  padding-right: 24px;
}

.header > .links {
  margin-left: 72px;
  flex-grow: 1;
  padding-top: 2px;
}

.header > .links a {
  margin-right: 28px;
  font-size: 14px;
}

.header > .account {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.content > .secret-list-wrapper {
  max-width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 64px;
}

.secret-list-wrapper > .spinner {
  display: block;
  margin: 80px auto 0;
}

.content > .form-wrapper {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 36px;
  margin-bottom: 80px;
}

.form-wrapper > .spinner {
  display: block;
  margin: 80px auto 0;
}

.form-wrapper > .no-wallet-wrapper {
  padding-top: 12px;
}
