html {
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;
}

body {
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  color: #2e384c;
}

a {
  color: #282B43;
}
